import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleEventDatePipe } from './custom-pipe/sale-event-date.pipe.';
import { NextTabDirective } from './custom-directive/next-tab.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SaleEventDatePipe, NextTabDirective],
  exports: [SaleEventDatePipe, NextTabDirective]
})
export class PurpleProjectWidgetsModule { }
