import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { BookingService, GetUserProfileBookingsResponse, PRPVUserAttributeDefaultValueFull, PolicyQuery, PurpleApiResponseStatus, User, UserAttributeValue, UserAttributeValuesItem, UserPastSaleEventBookingItem, UserProfileBookingItem, UserProfileBookingTransaction } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { Subject, forkJoin, lastValueFrom, takeUntil } from 'rxjs';
import { SaleEventPoliciesQuery } from 'src/core-modules/sdk/api/model/saleEventPoliciesQuery';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ShowPolicyPreviewModalComponent } from '../modals/show-policy-preview-modal/show-policy-preview-modal.component';
import { ShowPolicyPreviewDrawerComponent } from '../drawers/show-policy-preview-drawer/show-policy-preview-drawer.component';
import { Guid, Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleLoaderService, PurpleSelectFilter, PurpleStorageService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { CurrencyPipe, DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { EditEmailTranslations, VerifyAccountEmailModalComponent } from '../modals/verify-modals/verify-account-email-modal/verify-account-email-modal.component';
import { EditPhoneNumberTranslations, VerifyAccountPhoneModalComponent } from '../modals/verify-modals/verify-account-phone-modal/verify-account-phone-modal.component';

@Component({
  selector: 'app-private-user-profile',
  templateUrl: './private-user-profile.component.html',
  styleUrls: ['./private-user-profile.component.scss']
})

export class PrivateUserProfileComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, public tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, public policySvc: PolicyAndUserAttributesService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe,
    private authSvc: AuthenticationService, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, public bookSvc: BookingService, private authService: AuthenticationService, private modal: NzModalService,
    private drawerSvc: NzDrawerService, private storageSvc: PurpleStorageService, private psNotSvc: PurpleSalesNotificationService, @Inject(LOCALE_ID) private locale: string, private apiProxySvc: PurpleApiProxyService, private breakSvc: BreakpointsService,
    public seSvc: SaleEventService) {
  }

  sub: Subject<void> = new Subject();
  isLoading: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  refreshFields: string[] = [];


  externalAddress: string | undefined;
  currentExternalAddress: string | undefined;

  externalInvoiceAddress: string | undefined;
  currentExternalInvoiceAddress: string | undefined;
  currentUser: User = Helper.storeNewObj(this.authSvc.currentUser!.user!);
  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });

  saleEventPolicies: SaleEventPoliciesQuery[] = [];
  userAttributes: UserAttributeValuesItem[] = [];
  userBookingsDetail: GetUserProfileBookingsResponse | undefined;

  pageTitle: pageTitle = {
    title: this.tranPipe.transform('user_profile_title', 'Profilo', []),
    titleTranslationKey: 'user_profile_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform('user_profile_subtitle', 'I tuoi dati', []),
      subtitleTranslationKey: 'user_profile_subtitle'
    }
  }

  isLoadingPassword: boolean = false;
  isLoadingBookings: boolean = false;

  defaultSale: string = Guid.empty();

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  async ngOnInit() {
    this.loaderSvc.addRequest("general");

    forkJoin({
      externalInvoiceAddress: this.bookSvc.getUserInvoiceAddress(this.tsvc.currentLanguage.value),
      externalAdderess: this.bookSvc.getUserRegistrationAddress(this.tsvc.currentLanguage.value),
      policies: this.bookSvc.getUserPolicies(this.tsvc.currentLanguage.value),
      attributes: this.bookSvc.getUserAttributes(this.tsvc.currentLanguage.value, {
        showOnlyUserAttributes: true
      }),
      userBookingsDetail: this.bookSvc.getUserProfileBookings(this.tsvc.currentLanguage.value, {
        getCounters: true,
        pageNumber: 1,
        pageSize: 3
      })
    }).subscribe({
      next: value => {
        this.externalAddress = value.externalAdderess.data?.addressExternalId ?? undefined;
        this.externalInvoiceAddress = value.externalInvoiceAddress.data?.addressExternalId ?? undefined;
        this.saleEventPolicies = value.policies.data ?? [];
        this.userAttributes = value.attributes.data ?? [];
        this.userBookingsDetail = value.userBookingsDetail.data ?? undefined;
      },
      complete: () => {
        this.currentExternalAddress = Helper.storeNewObj(this.externalAddress);
        this.currentExternalInvoiceAddress = Helper.storeNewObj(this.externalInvoiceAddress);
        this.breakSvc.breakPointDevice$.pipe(takeUntil(this.sub)).subscribe((device: "mobile" | "tablet" | "desktop" | undefined) => {
          if (device == "mobile" || device == "tablet") {
            this.purpleFormFieldGroups = [
              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: false,
                fieldGroupPaddingRight: false,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'firstname',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
                    fieldValue: this.currentUser?.firstName,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'lastname',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
                    fieldValue: this.currentUser?.lastName,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'email',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
                    fieldValue: this.currentUser?.email,
                    fieldIsRequired: true,
                    fieldCustomValidators: [Validators.email],
                    fieldIsDisabled: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'phoneNumber',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
                    fieldValue: this.currentUser?.phone,
                    fieldIsRequired: true,
                    fieldIsDisabled: true,
                    fieldAffix: {
                      fieldControlName: "phonePrefix",
                      fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
                      fieldType: "select-local",
                      fieldWidth: "90px",
                      fieldIsRequired: true,
                      fieldIsDisabled: true,
                      fieldValue: this.currentUser?.phonePrefix,
                      fieldOptions: [
                        { id: 'type', value: 'default' },
                        { id: 'showArrow', value: false },
                        {
                          id: 'options', value: this.phonePrefixes
                        }
                      ]
                    },
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'date',
                    fieldControlName: 'birthdate',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_birthdate', 'Data di nascita', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_birthdate_placeholder', 'Data di nascita utente', []),
                    fieldValue: this.currentUser?.birthDate ? new Date(Date.parse(this.currentUser.birthDate)) : undefined,
                    fieldIsRequired: false,
                    fieldOptions: [{ id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'gender',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_gender', 'Sesso', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_gender_placeholder', 'Sesso utente', []),
                    fieldValue: this.currentUser?.genderId,
                    fieldIsRequired: false,
                    fieldOptions: [
                      { id: 'type', value: 'default' },
                      { id: 'showArrow', value: true },
                      {
                        id: 'options', value: [
                          {
                            label: this.tranPipe.transform('edit_user_gender_man', 'Uomo', []),
                            value: "M"
                          },
                          {
                            label: this.tranPipe.transform('edit_user_gender_female', 'Donna', []),
                            value: "F"
                          },
                          {
                            label: this.tranPipe.transform('edit_user_gender_other', 'Altro', []),
                            value: "O"
                          }
                        ]
                      }
                    ],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'taxId',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_user_taxId', 'Codice fiscale', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_taxId_placeholder', 'Codice fiscale', []),
                    fieldValue: this.currentUser?.taxId,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'invoiceAddress',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_profile_form_address_invoice_title', 'Indirizzo fatturazione', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_profile_form_address_invoice_placeholder', 'Indirizzo fatturazione', []),
                    fieldValue: this.currentExternalInvoiceAddress,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'searchFunction', value: this.addressInvoiceSearchNameFunction },
                      { id: 'searchByIdFunction', value: this.addressInvoiceSearchIdFunction },
                    ]
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'address',
                    fieldBootstrapColumn: 12,
                    fieldName: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('edit_profile_form_address_title_full', 'Indirizzo', []) : this.tranPipe.transform('edit_profile_form_address_title_city', 'Città', []),
                    fieldPlaceholder: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', []),
                    fieldValue: this.currentExternalAddress,
                    fieldIsRequired: true,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'searchFunction', value: this.addressSearchNameFunction },
                      { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
                    ]
                  }
                ]
              }
            ]
          } else {
            this.purpleFormFieldGroups = [
              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: false,
                fieldGroupPaddingRight: false,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'firstname',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
                    fieldValue: this.currentUser?.firstName,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'lastname',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
                    fieldValue: this.currentUser?.lastName,
                    fieldIsRequired: true,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'email',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
                    fieldValue: this.currentUser?.email,
                    fieldIsRequired: true,
                    fieldCustomValidators: [Validators.email],
                    fieldIsDisabled: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'phoneNumber',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
                    fieldValue: this.currentUser?.phone,
                    fieldIsRequired: true,
                    fieldIsDisabled: true,
                    fieldAffix: {
                      fieldControlName: "phonePrefix",
                      fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
                      fieldType: "select-local",
                      fieldWidth: "90px",
                      fieldIsRequired: true,
                      fieldIsDisabled: true,
                      fieldValue: this.currentUser?.phonePrefix,
                      fieldOptions: [
                        { id: 'type', value: 'default' },
                        { id: 'showArrow', value: false },
                        {
                          id: 'options', value: this.phonePrefixes
                        }
                      ]
                    },
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'date',
                    fieldControlName: 'birthdate',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_birthdate', 'Data di nascita', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_birthdate_placeholder', 'Data di nascita utente', []),
                    fieldValue: this.currentUser?.birthDate ? new Date(Date.parse(this.currentUser.birthDate)) : undefined,
                    fieldIsRequired: false,
                    fieldOptions: [{ id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'gender',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_gender', 'Sesso', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_gender_placeholder', 'Sesso utente', []),
                    fieldValue: this.currentUser?.genderId,
                    fieldIsRequired: false,
                    fieldOptions: [
                      { id: 'type', value: 'default' },
                      { id: 'showArrow', value: true },
                      {
                        id: 'options', value: [
                          {
                            label: this.tranPipe.transform('edit_user_gender_man', 'Uomo', []),
                            value: "M"
                          },
                          {
                            label: this.tranPipe.transform('edit_user_gender_female', 'Donna', []),
                            value: "F"
                          },
                          {
                            label: this.tranPipe.transform('edit_user_gender_other', 'Altro', []),
                            value: "O"
                          }
                        ]
                      }
                    ],
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'taxId',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_user_taxId', 'Codice fiscale', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_user_taxId_placeholder', 'Codice fiscale', []),
                    fieldValue: this.currentUser?.taxId,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'invoiceAddress',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_profile_form_address_invoice_title', 'Indirizzo fatturazione', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_profile_form_address_invoice_placeholder', 'Indirizzo fatturazione', []),
                    fieldValue: this.currentExternalInvoiceAddress,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'searchFunction', value: this.addressInvoiceSearchNameFunction },
                      { id: 'searchByIdFunction', value: this.addressInvoiceSearchIdFunction },
                    ]
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'address',
                    fieldBootstrapColumn: 12,
                    fieldName: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('edit_profile_form_address_title_full', 'Indirizzo', []) : this.tranPipe.transform('edit_profile_form_address_title_city', 'Città', []),
                    fieldPlaceholder: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', []),
                    fieldValue: this.currentExternalAddress,
                    fieldIsRequired: true,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'searchFunction', value: this.addressSearchNameFunction },
                      { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
                    ]
                  }
                ]
              }
            ]
          }
        });

        this.disableForm = true;

        this.loaderSvc.removeRequest("general");
      },
    });
  }

  getSaleEventName(seId: string, seName: string) {
    if (seId.strEq(this.defaultSale)) {
      return this.tranPipe.transform('general_policies_section', 'Generali', [])
    }

    return seName;
  }

  isAttributeSelected(selAttributes: UserAttributeValue[], defAttId: string) {
    return selAttributes.findIndex(f => f.userAttributeDefaultValueId.strEq(defAttId)) != -1;
  }


  async saveBaseInfoChanges() {
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.currentUser.firstName = this.validateForm.controls["firstname"].value;
      this.currentUser.lastName = this.validateForm.controls["lastname"].value;
      this.currentUser.birthDate = this.validateForm.controls["birthdate"].value;
      this.currentUser.genderId = this.validateForm.controls["gender"].value;
      this.currentUser.taxId = this.validateForm.controls["taxId"].value;
      this.currentExternalAddress = this.validateForm.controls["address"].value;
      this.currentExternalInvoiceAddress = this.validateForm.controls["invoiceAddress"].value;

      if (Helper.checkIfDoChanges(this.currentUser, this.authSvc.currentUser!.user!) || Helper.checkIfDoChanges(this.currentExternalAddress, this.externalAddress) || Helper.checkIfDoChanges(this.currentExternalInvoiceAddress, this.externalInvoiceAddress)) {

        await this.apiProxySvc.makeRequestErrorFunction<User>(() => this.bookSvc.updateBaseUserInfo(this.tsvc.currentLanguage.value, {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          birthDate: this.currentUser.birthDate,
          genderId: this.currentUser.genderId,
          externalAddress: this.currentExternalAddress,
          externalInvoiceAddress: this.currentExternalInvoiceAddress,
          taxId: this.currentUser.taxId
        }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<User>) => {
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('update_base_user_info_error_title', 'Errore', []),
            res.message,
            this.tranPipe.transform('update_base_user_info_error_button', 'Ok', []),
            undefined, 'modal', true, undefined, undefined, false, false, false, false
          )
        }, (res: PurpleApiMakeRequestResponse<User>) => {
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('update_base_user_info_success_title', 'Aggiornamento completato', []),
            this.tranPipe.transform('update_base_user_info_success_message', 'Informazioni aggiornate con successo', []),
            this.tranPipe.transform('update_base_user_info_success_button', 'Ok', []),
            () => {
              this.authSvc.currentUser!.user = res.data;
              this.storageSvc.set('CurrentUser', this.authSvc.currentUser);
              this.currentUser = Helper.storeNewObj(this.authSvc.currentUser!.user!);
              this.disableForm = true;
            }, 'modal', true, undefined, undefined, false, false, false, false
          )
        })

      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
      }
    }

    this.isLoading = false;
  }

  enableForm() {
    this.disableForm = false;
  }

  cancelEdit() {
    this.disableForm = true;
    this.restoreForm();
  }

  restoreForm() {
    this.validateForm.controls["firstname"].setValue(this.currentUser.firstName);
    this.validateForm.controls["lastname"].setValue(this.currentUser.lastName);
    this.validateForm.controls["email"].setValue(this.currentUser.email);
    this.validateForm.controls["birthdate"].setValue(this.currentUser.birthDate);
    this.validateForm.controls["gender"].setValue(this.currentUser.genderId);
    this.validateForm.controls["phonePrefix"].setValue(this.currentUser.phonePrefix);
    this.validateForm.controls["phoneNumber"].setValue(this.currentUser.phone);
    this.validateForm.controls["taxId"].setValue(this.currentUser.taxId);

    this.purpleFormFieldGroups![0].formFields.find(f => f.fieldControlName.strEq("address"))!.fieldValue = this.externalAddress;
    this.validateForm.controls["address"].setValue(this.externalAddress);

    this.purpleFormFieldGroups![0].formFields.find(f => f.fieldControlName.strEq("invoiceAddress"))!.fieldValue = this.externalInvoiceAddress;
    this.validateForm.controls["invoiceAddress"].setValue(this.externalInvoiceAddress);

    this.refreshFields = [
      "address", "invoiceAddress"
    ]

    setTimeout(() => {
      this.refreshFields = []
    }, 200);
  }


  requestResetPassword() {
    this.isLoadingPassword = true;
    this.authService.requestResetPassword(this.currentUser.email, window.location.hostname).then(
      async (res: { isOk: boolean, message: string }) => {
        if (res.isOk) {
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('request_reset_password_modal_success_title', 'Richiesta inviata', []),
            this.tranPipe.transform('request_reset_password_modal_success_subtitle', 'La tua richiesta di reset della password è stata correttamente inviata al tuo indirizzo email', []),
            this.tranPipe.transform('request_reset_password_modal_success_button', 'Ok', []),
            undefined, 'modal', true, undefined, undefined, true, false, true, false)
        }
        else {
          this.psNotSvc.showMotSimpleNotification(
            this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
            res.message,
            this.tranPipe.transform('error_message_retry_button', 'Riprova', []),
            () => {
              this.requestResetPassword()
            }, 'modal', true, undefined, undefined, false, false, false, false)
        }
      }
    )
    this.isLoadingPassword = false;
  }

  async getUserAttributes() {
    const res = await lastValueFrom(this.bookSvc.getUserAttributes(this.tsvc.currentLanguage.value, {
      showOnlyUserAttributes: true
    }))

    if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {
      this.userAttributes = res.data!
    } else {
      this.userAttributes = []
    }
  }


  addressSearchNameFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByIdSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }

  addressInvoiceSearchNameFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }

  addressInvoiceSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByIdSelect(args.culture, args)
  }

  //BOOKINGS
  async loadMoreBookings() {
    this.isLoadingBookings = true;
    await this.apiProxySvc.makeRequestErrorFunction<GetUserProfileBookingsResponse>(() => this.bookSvc.getUserProfileBookings(this.tsvc.currentLanguage.value, {
      getCounters: false,
      pageNumber: (this.userBookingsDetail?.pageNumber ?? 1) + 1,
      pageSize: 3
    }), false, "verify-account-loader", 50000, (res: PurpleApiMakeRequestResponse<GetUserProfileBookingsResponse>) => {

      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('get_user_profile_bookings_error_title', 'Errore', []),
        this.tranPipe.transform('get_user_profile_bookings_error_subtitle', 'Caricamento fallito', []),
        this.tranPipe.transform('get_user_profile_bookings_error_button', 'Riprova', []),
        async () => { await this.loadMoreBookings() }, 'modal', true, undefined, undefined, true, false, true, false)

    }, (res: PurpleApiMakeRequestResponse<GetUserProfileBookingsResponse>) => {
      this.userBookingsDetail!.bookings = [...this.userBookingsDetail!.bookings, ...res.data!.bookings];
      this.userBookingsDetail!.pageNumber = res.data!.pageNumber;
      this.userBookingsDetail!.pageSize = res.data!.pageSize;
      this.userBookingsDetail!.showMore = res.data!.showMore;
    })
    this.isLoadingBookings = false;
  }


  //ATTRIBUTES
  async addOrRemovePreference(attribute: UserAttributeValuesItem, userAttributeDefaultValueId: string) {
    var current: UserAttributeValue | undefined = attribute.userAttributeValues.
      find(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId));

    const defaultVal: PRPVUserAttributeDefaultValueFull | undefined = attribute.defaultValues.
      find(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId));
    var toUpd = attribute.userAttributeValues;
    if (current == undefined) {
      toUpd.push({
        isDefaultValue: true,
        userAttributeDefaultValueId: defaultVal!.userAttributeDefaultValueId,
        userAttributeId: attribute.userAttributeId,
        userAttributeValueId: Guid.newGuid(),
        userAttributeValueText: defaultVal!.userAttributeDefaultValueText,
        userId: this.authSvc.currentUser!.user!.userId,
        isActive: true,
        timeStamp: undefined
      })
    } else {

      const idx = toUpd.findIndex(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId) &&
        f.userId.strEq(this.authSvc.currentUser!.user!.userId)
      )

      if (idx != -1) {
        toUpd.splice(idx, 1)
      }
    }
    const res = await lastValueFrom(this.bookSvc.addOrRemoveUserAttributeValue(this.tsvc.currentLanguage.value, {
      userAttributeValues: [{
        userAttributeId: attribute.userAttributeId,
        userAttributeValues: toUpd
      }]
    }));

    if (res.status == PurpleApiResponseStatus.Success) {

      this.mesSvc.success(
        current ?
          this.tranPipe.transform('remove_attribute_value_ok', 'Preferenza rimossa con successo', []) :
          this.tranPipe.transform('add_attribute_value_ok', 'Preferenza aggiunta con successo', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
      await this.getUserAttributes();
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        res.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)
    }
  }



  //POLICIES  
  policyAccepted(policy: PolicyQuery): boolean {
    if (policy.isMandatoryPolicy) {
      return true;
    }

    if (policy.policyPage.strEq("footer")) {
      return true;
    }

    if (policy.accepted) {
      return true;
    }

    return false;
  }

  policyBlocked(policy: PolicyQuery): boolean {
    if (policy.isMandatoryPolicy) {
      return true;
    }

    if (policy.policyPage.strEq("footer")) {
      return true;
    }

    return false;
  }

  async updateSaleEventUserPolicyEndorsement(value: boolean, policy: PolicyQuery) {
    //console.log("CAMBIOOO")
    const res = await lastValueFrom(this.bookSvc.updateSaleEventUserPolicyEndorsement(this.tsvc.currentLanguage.value, {
      saleEventId: policy.saleEventId,
      accepted: value,
      policyId: policy.policyId
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.mesSvc.success(this.tranPipe.transform('update_sale_event_user_policy_endsorsment_ok', 'Aggiornato con successo', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
    } else {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('error_message_default_title', 'Qualcosa è andato storto', []),
        res.message,
        this.tranPipe.transform('error_message_default_button', 'Ok', []),
        undefined, 'modal', true, undefined, undefined, false, false, false, false)
    }
  }

  showPolicyPreview(policy: PolicyQuery, isMobile: boolean = false) {
    if (isMobile) {
      this.drawerSvc.create<ShowPolicyPreviewDrawerComponent, { value: string }, string>({
        nzTitle: this.tranPipe.transform("drawer_policy_preview_title", "Policy Preview", []),
        nzContent: ShowPolicyPreviewDrawerComponent,
        nzClosable: true,
        nzWrapClassName: "show-policy-preview",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          policy: policy
        }
      })
    } else {
      this.modal.create<ShowPolicyPreviewModalComponent, PolicyQuery>({
        nzTitle: this.tranPipe.transform("modal_policy_preview_title", "Policy Preview", []),
        nzContent: ShowPolicyPreviewModalComponent,
        nzData: policy,
        nzWidth: '800px',
        nzClassName: 'ps-modal',
        nzFooter: null,
        nzCentered: true,
        nzMaskClosable: false
      });
    }
  }

  openVerify(type: 'email' | 'phone') {
    switch (type) {
      case 'email':
        this.modal.create<VerifyAccountEmailModalComponent, EditEmailTranslations>({
          nzTitle: this.breakSvc.breakPointDevice$.value != 'desktop' ?
            this.tranPipe.transform("edit_email_accountmodal_mobile_title", "Verifica email", []) :
            this.tranPipe.transform("edit_email_accountmodal_title", "Verifica indirizzo email", []),
          nzContent: VerifyAccountEmailModalComponent,
          nzData: {
            title: {
              key: "edit_email_accounttitle",
              value: this.tranPipe.transform('edit_email_accounttitle','Seleziona l\'indirizzo email corretto', [])
            },
            subtitle: {
              key: "edit_email_accountsubtitle",
              value: this.tranPipe.transform('edit_email_accountsubtitle','Clicca il bottone sottostante e segui le istruzioni per verificare la tua email.', [])
            },
            button: {
              key: "edit_email_accountbutton",
              value: this.tranPipe.transform('edit_email_accountbutton','Invia email di verifica', [])
            }
          },
          nzWidth: '850px',
          nzClassName: 'purple-sales-modal',
          nzFooter: null,
          nzCentered: false,
          nzMaskClosable: false,
          nzClosable: true
        });
        break;
      case 'phone':
        this.modal.create<VerifyAccountPhoneModalComponent, EditPhoneNumberTranslations>({
          nzTitle: this.breakSvc.breakPointDevice$.value != 'desktop' ?
            this.tranPipe.transform("edit_phone_account_mobile_modal_title", "Verifica telefono", []) :
            this.tranPipe.transform("edit_phone_account_modal_title", "Verifica numero di telefono", []),
          nzContent: VerifyAccountPhoneModalComponent,
          nzData: {
            title: {
              key: "edit_phone_account_title",
              value: this.tranPipe.transform('edit_phone_accounttitle','Inserisci il numero di telefono corretto', [])
            },
            subtitle: {
              key: "edit_phone_account_subtitle",
              value: this.tranPipe.transform('edit_phone_accounttitle','Clicca sul bottone sottostante per ricevere il codice di verifica sms sul numero specificato', [])
            },
            sendButton: {
              key: "edit_phone_account_send_button",
              value: this.tranPipe.transform('edit_phone_accountsend_button','Invia sms codice', [])
            },
            insertCodeTitle: {
              key: "edit_phone_account_insert_code_title",
              value: this.tranPipe.transform('edit_phone_accountinsert_code_title','Inserisci il codice di verifica a 6 cifre', [])
            },
            insertCodeSubtitle: {
              key: "edit_phone_account_insert_code_subtitle",
              value: this.tranPipe.transform('edit_phone_accountinsert_code_subtitle','Il codice è stato inviato al numero {0}.', [])
            },
            editNumberButton: {
              key: "edit_phone_account_reinsert_number_button",
              value: this.tranPipe.transform('edit_phone_accountreinsert_number_button','Modifica numero', [])
            },
            resendCodeButton: {
              key: "edit_phone_account_resend_code_button",
              value: this.tranPipe.transform('edit_phone_accountresend_code_button','Reinvia codice', [])
            },
            insertCodeButton: {
              key: "edit_phone_account_insert_code_button",
              value: this.tranPipe.transform('edit_phone_accountinsert_code_button','Verifica numero', [])
            }
          },
          nzWidth: '850px',
          nzClassName: 'purple-sales-modal',
          nzFooter: null,
          nzCentered: false,
          nzMaskClosable: false,
          nzClosable: true
        }).afterClose.subscribe(() => {
          this.currentUser.phonePrefix = this.authService.currentUser!.user!.phonePrefix;
          this.currentUser.phone= this.authService.currentUser!.user!.phone;
          this.validateForm.controls["phonePrefix"].setValue(this.currentUser.phonePrefix);
          this.validateForm.controls["phoneNumber"].setValue(this.currentUser.phone);
        });
        break;

      default:
        break;
    }

  }

  getCurrentBookTransactions(bookingId: string, books: UserProfileBookingItem): UserProfileBookingTransaction[]{
    return books.transactions.filter(f=> f.bookingId.strEq(bookingId));
  }

  getTransactionTotals(tran: UserProfileBookingTransaction, book: UserProfileBookingItem): string{
    return book.checkInOk ? 
    this.currencyPipe.transform(tran.transactionTotalAmount, "EUR")!: "-";
  }
}
