import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { Subscription } from 'rxjs';
import { PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { Guid, PurpleEditService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['../authentication.scss', './request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit, OnDestroy {
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  seId: string = "";
  subs: Subscription = new Subscription();
  isLoading: boolean = false;

  pageTitle: pageTitle = {
    title: this.tranPipe.transform("forgot_password_title", "Reset Password", []),
    titleTranslationKey: 'forgot_password_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform("forgot_password_subtitle", "Richiedi il reset della password"),
      subtitleTranslationKey: 'forgot_password_subtitle'
    },
    textAlign: 'left'
  }

  constructor(private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private authenticationService: AuthenticationService, private router: Router, private editSvc: PurpleEditService,
    private tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private breakSvc: BreakpointsService, private psNotSvc: PurpleSalesNotificationService) { }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(params => {
      this.seSvc.getSaleEventParameters(params);
    });

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('forgot_passoword_form_email', 'Inserisci il tuo indirizzo email o telefono', []),
            fieldPlaceholder: this.tranPipe.transform('forgot_passoword_form_email_placeholder', 'Indirizzo email o telefono (no prefisso)', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async requestResetPassword(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      this.authenticationService.requestResetPassword(this.validateForm.controls["email"].value, window.location.hostname).then(
        async (res: {isOk: boolean, message: string}) => {
          const isMobile = this.breakSvc.breakPointDevice$.value != 'desktop';
          var title = "";

          if (res.isOk) {
            if (isMobile) {
              title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ok", "Richiesta inviata")
            } else {
              title = this.tranPipe.transform("modal_request_reset_password_title_ok", "Richiesta inviata correttamente")
            }
          } else {
            if (isMobile) {
              title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ko", "Errore")
            } else {
              title = this.tranPipe.transform("modal_request_reset_password_title_ko", "Ops! qualcosa è andato storto")
            }
          }

          this.psNotSvc.showMotSimpleNotification(
            title,
            res.message,
            this.tranPipe.transform("request_reset_password_modal_button", "Conferma"),
            async () => {
              if (res.isOk) {
                await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
              }
            },
            'modal',
            true,
            undefined,
            undefined,
            false,
            false,
            false,
            false
          )
        }
      )
    }
  }
}
