import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { ReferralService } from 'src/app/referral/referral.service';
import { PRPVSaleEventFull } from 'src/core-modules/sdk/api';
import { Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { Guid, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../authentication.scss', './login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  seId: string = "";
  subs: Subscription = new Subscription();
  companyName = environment.COMPANY_NAME ?? "purple_sales_backoffice";
  isLoading: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  rememberCheck: boolean = true;

  pageTitle: pageTitle = {
    title: this.tranPipe.transform("login_title_new", "Benvenuto in {0}!", [this.companyName]),
    titleTranslationKey: 'login_title_desk_new',
    subtitle:
    {
      subtitle: this.tranPipe.transform("login_log_with_saywow_account", "Accedi con l\'account saywow", [this.companyName]),
      subtitleTranslationKey: 'login_subtitle'
    },
    textAlign: 'left'
  }

  constructor(private fb: FormBuilder, private authSvc: AuthenticationService, private router: Router, private refSvc: ReferralService,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, 
    private policySvc: PolicyAndUserAttributesService, private breakSvc: BreakpointsService) {
  }

  async login() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const authenticated = await this.authSvc.login(this.validateForm.controls["email"].value, this.validateForm.controls["password"].value, this.rememberCheck ? 'local' : 'session');

      if (authenticated) {
        await this.refSvc.reedemReferralCode();
        await this.policySvc.checkUserAttributesAndPolicies(this.breakSvc.breakPointDevice$.value != 'desktop');

        if (this.seSvc.returnUrl != undefined) {
          await this.router.navigateByUrl(this.seSvc.returnUrl)
        } else {
          await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
        }

      }
    }
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined)=>{
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.route.queryParams.subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
    });

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('login_page_form_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('login_page_form_email_placeholder', 'Email', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldCustomValidators: [Validators.email],
            fieldCustomValidatorErrors: [{ errorId: "email", text: this.tranPipe.transform("invalid_email_validator_error_text", "Email non valida") }]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'password',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('login_page_form_password', 'Password', []),
            fieldPlaceholder: this.tranPipe.transform('login_page_form_password_placeholder', 'Password', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'isPassword', value: true }]
          }
        ]
      }
    ]
  }
}
