<nz-spin class="ps-d-flex ps-flex-wrap ps-w-100 verify-account-spin" [nzSpinning]="isLoading">
  <div class="ps-d-flex ps-flex-wrap ps-w-100 verify-account-container">


    <div *ngIf="!(userStatus?.isEmailVerified??false)" 
    class="{{(userStatus?.isPhoneVerified??false)?'ps-col-12': ((breakSvc.breakPointDevice$ | async)=='mobile'?'ps-col-12':'ps-col-6')}} ps-d-flex ps-flex-wrap">
      <p purpleEditableText tranKey='verify_account_email_title' class="verify-account-section-title">
        {{ 'verify_account_email_title' | translation: 'Verifica indirizzo email': []}}
      </p>
      <p purpleEditableText tranKey='verify_account_email_subtitle' class="verify-account-section-subtitle">
        {{ 'verify_account_email_subtitle' | translation: 'Segui gli step indicati per verificare l\'indirizzo email':
        []}}
      </p>
      <div class="ps-w-100 ps-d-flex ps-justify-content-center verify-account-button-container">
        <app-purple-sales-button (click)="openVerify('email')" purpleEditableText tranKey='verify_account_button_now'
          [buttonText]="'verify_account_button_now' | translation: 'Verifica ora'">
        </app-purple-sales-button>
      </div>
    </div>


    <div *ngIf="!(userStatus?.isPhoneVerified??false)" 
    class="{{(userStatus?.isEmailVerified??false)?'ps-col-12':((breakSvc.breakPointDevice$ | async)=='mobile'?'ps-col-12':'ps-col-6')}} ps-d-flex ps-flex-wrap">
      <p purpleEditableText tranKey='verify_account_phone_title' class="verify-account-section-title">
        {{ 'verify_account_phone_title' | translation: 'Verifica numero di telefono': []}}
      </p>
      <p purpleEditableText tranKey='verify_account_phone_subtitle' class="verify-account-section-subtitle">
        {{ 'verify_account_phone_subtitle' | 
        translation: 'Segui gli step indicati per verificare il numero di telefono': []}}
      </p>
      <div class="ps-w-100 ps-d-flex ps-justify-content-center verify-account-button-container">
        <app-purple-sales-button (click)="openVerify('phone')" purpleEditableText tranKey='verify_account_button_now'
          [buttonText]="'verify_account_button_now' | translation: 'Verifica ora'">
        </app-purple-sales-button>
      </div>
    </div>
  </div>
</nz-spin>