import pkg from '../../../package.json';

export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true,
  COMPANY_NAME: 'SayWow',
  COMPANY_ABB: 'sw',
  SHOW_COUNTRY_SELECT: false,
  COUNTRY_SELECT: [
    {
      COUNTRY_NAME: 'United States Of America', 
      COUNTRY_FLAG: 'us',
      COUNTRY_BASE_PATH: 'https://api.saywow.us',
      //COUNTRY_MEDIA_PATH: "https://test-media-sw-us.saywow.us",
      COUNTRY_MEDIA_PATH: "https://media-sw-us.saywow.us",
      COUNTRY_DEFAULT_LANGUAGE: 'en-us',
    }
  ],
  DEFAULT_PHONE_PREFIX : "+1",
  MESSAGE_DURATION: 2500,
  NEW_TRAN_SYMBOL: '',
  EDIT_TRAN_SYMBOL: '',
  EDIT_TRAN_AFFIX: 'booking_',
  ERROR_NOTIFICATION_DURATION: 0,
  LOADER_DELAY: 200,
  SAFARI_COLOR: '#ff0000',
  HTML_THEME_COLOR: '#ffffff',
  HTML_TITLE_COLOR: '#ffc40d',
  REGISTRATION_FULL_ADDRESS: false,
  USE_IUBENDA: true,
  IUBENDA_COOKIE_POLICY:
    'var _iub = _iub || []; _iub.csConfiguration = {"countryDetection":true,"enableRemoteConsent":true,"enableUspr":true,"floatingPreferencesButtonDisplay":"bottom-right","perPurposeConsent":true,"siteId":2538161,"whitelabel":false,"gdprAppliesGlobally":false,"cookiePolicyId":31646793,"lang":"en", "banner":{ "acceptButtonCaptionColor":"#000000","acceptButtonColor":"#FECA00","acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonCaptionColor":"#000000","customizeButtonColor":"#FECA00","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","fontSizeCloseButton":"18px","listPurposes":true,"logo":null,"position":"bottom","rejectButtonCaptionColor":"#000000","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true,"showPurposesToggles":true }};',
  IUBENDA_CONS:
    'var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "3bIDiM3EIufKwstNI1ATtrpphMC4k52s"}]);',
  IUBENDA_DEFAULT_HTML:
    '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);',
  SHOW_PUBLIC_EVENT: false,
  SHOW_EVENTS_SALE_NAME: false,
  SHOW_CHEK_IN_INFO: false,
  SHOW_ALL_PRIVATE_SALE_CONTACTS: false,
  SHOW_MEMBERSHIPS: false,
  REGISTER_TYPE: 'full',
  DEFAULT_MENU_LEVEL: 1000
};
