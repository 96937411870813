<div *ngIf="saleEventDetail != undefined"
  class="booking-sale-detail-main-container ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">

  <app-base-sale-event-detail-banner *ngIf="(saleEventDetail.saleEvent.saleEventDetailLayout??'base') == 'base'"
    class="ps-w-100" [saleEventDetail]="saleEventDetail" [scrollFn]="scrollTo"></app-base-sale-event-detail-banner>

  <app-advanced-sale-event-detail-banner *ngIf="(saleEventDetail.saleEvent.saleEventDetailLayout??'base') == 'avanzata'"
    class="ps-w-100" [saleEventDetail]="saleEventDetail"></app-advanced-sale-event-detail-banner>

  <container-element class="ps-d-flex ps-flex-wrap ps-justify-content-center booking-sale-detail-sub-container"
    [ngSwitch]="saleEventDetail.saleEventUserStatus.bookingClosed">

    <ng-container *ngSwitchCase="false">

      <!-- DESKTOP -->
      <div class="ps-w-100 ps-justify-content-center ps-flex-wrap ps-d-none ps-d-md-flex ">

        <div *ngIf="saleEventDetail.userSlotBooked != undefined && !saleEventDetail.userSlotBooked.pastSlot"
          class="booking-calendar-active-booking-banner ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between">
          <p class="booking-calendar-active-booking-banner-text">
            {{ 'active_booking_banner' | translation: 'Hai una prenotazione attiva:': []}}
            <span>
              {{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate: "longDate"}}
              ({{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate:
              "shortTime"}} - {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}})
            </span>
          </p>
          <button (click)="unbook(false)" nz-button nzType="primary"
            class="booking-calendar-active-booking-banner-button">
            {{ 'active_booking_banner_unbook_button' | translation: 'Cancella Prenotazione': []}}
          </button>
        </div>

        <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title>

        <div class="class-calendar-carousel-container">
          <ngu-carousel *ngIf="saleEventDetail != undefined && showCalendar" class="class-calendar-carousel ps-w-100"
            [inputs]="dateCalendarDesktopSliderConfig" [dataSource]="saleEventDetail.daySlots" #dayCarousel>
            <ngu-tile *nguCarouselDef="let item; let i = index">
              <div (click)="setCurrentDay(item)"
                class="class-calendar-carousel-day-container {{isSelectedDay(item.slotDayDate)?'selected':''}}">
                <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                  <p class="class-calendar-carousel-day-name">{{item.slotDayDate | date: "EEEE"}}</p>
                  <p class="class-calendar-carousel-day-number">{{item.slotDayDate | date: "d MMMM"}}</p>
                </div>
                <!-- <div class="class-calendar-carousel-day-underline"></div> -->
              </div>
            </ngu-tile>
            <button NguCarouselPrev class='class-calendar-carousel-arrow left'>
              <span nz-icon class="fsp-25-0" nzType="arrow-left" nzTheme="outline"></span>
            </button>
            <button NguCarouselNext class='class-calendar-carousel-arrow right'>
              <span class="fsp-25-0" nz-icon nzType="arrow-right" nzTheme="outline"></span>
            </button>
          </ngu-carousel>
        </div>



        <div *ngIf="saleEventDetail!.daySlots.length > 0 && currentDay != undefined"
          class="booking-calendar-day-slots-container sw-border-radius">

          <div (click)="showBookingModal(slot, currentDay.dayGmtInfo)" *ngFor="let slot of currentDay.slots" class="booking-calendar-day-slots-item {{slot.bookedByUser?'booked':''}} 
            {{checkIfSlotIsFull(slot)?'sold-out':''}} {{slot.canBookSlot?'':'not-bookable'}}" nz-tooltip
            [nzTooltipTitle]="'subscribe_to_book_slot' | translation: 'Abbonati': []"
            [nzTooltipTrigger]="(slot.containsMembershipRoles&&!slot.canBookSlot)?'hover':null"
            nzTooltipPlacement="bottom" nzTooltipOverlayClassName="subscribe-tooltip">
            <div class="slot-name-container">
              <ng-container class="ps-w-100" *ngIf="slot.slotName">
                <span class="slot-name-star-icon" nz-icon nzType="star" nzTheme="fill"></span>
                <p class="slot-name">{{slot.slotName}}</p>
                <span class="slot-name-star-icon" nz-icon nzType="star" nzTheme="fill"></span>
              </ng-container>
            </div>
            <p class="ps-w-100 ps-text-center slot-date">
              {{slot.slotStartDate | saleEventDate: 'shortTime' }} -
              {{slot.slotEndDate | saleEventDate: 'shortTime'}}
            </p>
            <p class="slot-status" purpleEditableText [tranKey]='slot.bookedByUser?"slot_booked":"sold_out"'>
              <span *ngIf="checkIfSlotIsFull(slot) && !slot.bookedByUser">{{ 'sold_out' | translation: 'sold out':
                []}}</span>
              <span *ngIf="slot.bookedByUser">{{ 'slot_booked' | translation: 'booked': []}}</span>
            </p>
          </div>


        </div>
      </div>

      <!-- MOBILE -->
      <div *ngIf="saleEventDetail.userSlotBooked != undefined && !saleEventDetail.userSlotBooked.pastSlot"
        class="booking-calendar-active-booking-banner ps-w-100 ps-d-flex ps-d-md-none  ps-align-items-center ps-justify-content-between">
        <p class="booking-calendar-active-booking-banner-text">
          {{ 'active_booking_banner' | translation: 'Hai una prenotazione attiva:': []}}
          <span>
            {{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate: "longDate"}}
            ({{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate:
            "shortTime"}} - {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}})
          </span>
        </p>
        <button (click)="unbook(true)" nz-button nzType="primary" class="booking-calendar-active-booking-banner-button">
          <span class="booking-calendar-active-booking-banner-button-icon" nz-icon nzType="delete"
            nzTheme="outline"></span>
        </button>
      </div>
      <div
        class="ps-w-100 ps-justify-content-center ps-flex-wrap ps-d-flex ps-d-md-none mobile-booking-calendar-days-container">
        <app-purple-sales-page-title class="book-slot-title" [pageTitle]="pageTitle"></app-purple-sales-page-title>
        <mat-expansion-panel *ngFor="let daySlot of saleEventDetail!.daySlots" [expanded]="panelActive(daySlot)"
          class="sw-border-radius">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div
                class="collapse-header ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-center ps-justify-content-center">
                <p class="collapse-header-date-day ps-w-100 ps-text-center">{{daySlot.slotDayDate | saleEventDate:
                  'EEEE'}}</p>
                <p class="collapse-header-date-date ps-w-100 ps-m-0 ps-text-center">{{daySlot.slotDayDate |
                  saleEventDate:
                  'dd MMMM' | titlecase}}</p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="collapse-content-container ps-d-flex ps-flex-wrap ps-w-100">

            <div class="ps-d-flex ps-col-6 booking-calendar-day-slots-item-container"
              *ngFor="let slot of daySlot.slots">
              <div (click)="showBookingDrawer(slot, daySlot.dayGmtInfo)"
                class="booking-calendar-day-slots-item disable-select
              {{slot.bookedByUser?'booked':''}} {{checkIfSlotIsFull(slot)?'sold-out':''}} 
              {{slot.canBookSlot?'':'not-bookable'}}" nz-tooltip
                [nzTooltipTitle]="'subscribe_to_book_slot' | translation: 'Abbonati': []"
                [nzTooltipTrigger]="(slot.containsMembershipRoles&&!slot.canBookSlot)?'click':null">
                <div class="slot-name-container disable-select">
                  <ng-container class="ps-w-100" *ngIf="slot.slotName">
                    <span class="slot-name-star-icon" nz-icon nzType="star" nzTheme="fill"></span>
                    <p class="slot-name disable-select">{{slot.slotName}}</p>
                    <span class="slot-name-star-icon" nz-icon nzType="star" nzTheme="fill"></span>
                  </ng-container>
                </div>
                <p class="ps-w-100 ps-text-center slot-date disable-select">
                  {{slot.slotStartDate | saleEventDate: 'shortTime' }} -
                  {{slot.slotEndDate | saleEventDate: 'shortTime'}}
                </p>
                <p class="slot-status" purpleEditableText [tranKey]='slot.bookedByUser?"slot_booked":"sold_out"'>
                  <span *ngIf="checkIfSlotIsFull(slot) && !slot.bookedByUser">{{ 'sold_out' | translation: 'sold out':
                    []}}</span>
                  <span *ngIf="slot.bookedByUser">{{ 'slot_booked' | translation: 'booked': []}}</span>
                </p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>



    <ng-container *ngSwitchCase="true">
      <div *ngIf="saleEventDetail.userSlotBooked != undefined && !saleEventDetail.userSlotBooked.pastSlot"
          class="booking-calendar-active-booking-banner ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between">
          <p class="booking-calendar-active-booking-banner-text">
            {{ 'active_booking_banner' | translation: 'Hai una prenotazione attiva:': []}}
            <span>
              {{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate: "longDate"}}
              ({{saleEventDetail.userSlotBooked!.slotStartDate| saleEventDate:
              "shortTime"}} - {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}})
            </span>
          </p> 
        </div>
      <!-- TODO -->
      <!-- <app-purple-sales-page-title [pageTitle]="pageTitle"></app-purple-sales-page-title> -->
      <div class="booking-closed-container">
        <h1 class="booking-closed-title">
          {{saleEventDetail.saleEventUserStatus.statusTitle}}
        </h1>
        <p class="booking-closed-subtitle">
          {{saleEventDetail.saleEventUserStatus.statusSubtitle}}
        </p>
        <div *ngIf="saleEventDetail.saleEventUserStatus.showBookingDetail || saleEventDetail.saleEventUserStatus.showInvitedFriends"  
        class="booking-closed-info">
          <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">
            <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_reservation_title'
              class="booking-closed-info-title ps-w-100">
              {{ 'sale_event_detail_closed_booking_your_reservation_title' | translation: 'La tua prenotazione': []}}
            </p>

            <container-element *ngIf="saleEventDetail.saleEventUserStatus.showBookingDetail" 
            class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="(saleEventDetail.userSlotBooked??undefined) != undefined">
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100">
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "longDate"}}
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "shortTime"}} -
                  {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_booked_slot'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_booked_slot' | translation: 'Nessuna prenotazione': []}}
                </p>
              </ng-container>
            </container-element>

            <container-element *ngIf="saleEventDetail.saleEventUserStatus.showInvitedFriends" 
            class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="invitedFriends.length > 0">
              <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_guests_title'
                class="booking-closed-info-title guest ps-w-100">
                {{ 'sale_event_detail_closed_booking_your_guests_title' | translation: 'I tuoi invitati': []}}
              </p>
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100" *ngFor="let friend of invitedFriends">
                  {{friend.firstName}} {{friend.lastName}} ({{friend.email}})
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_guests'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_guests' | translation: 'Nessun invitato': []}}
                </p>
              </ng-container>
            </container-element>

          </div>
        </div>
      </div>
      
      
      
      <!-- <div class="ps-w-100 ps-d-flex ps-flex-wrap booking-closed-container ps-justify-content-center">
        <h2 class="ps-w-100 ps-text-center booking-closed-title" purpleEditableText
          tranKey='sale_event_detail_closed_booking_closed_title'>
          {{ 'sale_event_detail_closed_booking_closed_title' | translation: 'Prenotazioni chiuse': []}}
        </h2>
        <p class="ps-w-100 ps-text-center booking-closed-subtitle" purpleEditableText
          tranKey='sale_event_detail_closed_booking_closed_subtitle'>
          {{ 'sale_event_detail_closed_booking_closed_subtitle' |
          translation: 'Le iscrizioni all\'evento sono chiuse, il riepilogo delle tue informazioni:': []}}
        </p>
        <div class="booking-closed-info">
          <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">
            <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_reservation_title'
              class="booking-closed-info-title ps-w-100">
              {{ 'sale_event_detail_closed_booking_your_reservation_title' | translation: 'La tua prenotazione': []}}
            </p>
            <container-element class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="(saleEventDetail.userSlotBooked??undefined) != undefined">
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100">
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "longDate"}}
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "shortTime"}} -
                  {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_booked_slot'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_booked_slot' | translation: 'Nessuna prenotazione': []}}
                </p>
              </ng-container>
            </container-element>

            <container-element class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="invitedFriends.length > 0">
              <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_guests_title'
                class="booking-closed-info-title guest ps-w-100">
                {{ 'sale_event_detail_closed_booking_your_guests_title' | translation: 'I tuoi invitati': []}}
              </p>
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100" *ngFor="let friend of invitedFriends">
                  {{friend.firstName}} {{friend.lastName}} ({{friend.email}})
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_guests'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_guests' | translation: 'Nessun invitato': []}}
                </p>
              </ng-container>
            </container-element>

          </div>
        </div>
      </div> -->




    </ng-container>
  </container-element>
</div>