import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PolicyItem, SaleEventContactInfo, SaleEventContactInfoItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { LayoutService } from '../layout.service';
function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}
@Component({
  selector: 'app-purple-sales-footer',
  templateUrl: './purple-sales-footer.component.html',
  styleUrls: ['./purple-sales-footer.component.scss']
})
export class PurpleSalesFooterComponent implements OnInit, OnDestroy {

  constructor(public tsvc: AppTranslationService, public policySvc: PolicyAndUserAttributesService, private breakSvc: BreakpointsService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService, public layoutSvc: LayoutService, private bookSvc: BookingService, private apiProxySvc: PurpleApiProxyService) { }

  currentYear: string = new Date().getFullYear().toString();
  swVersion: string = environment.appVersion;
  basePath: string = localStorage.getItem("BasePath")!;
  defLan: string = localStorage.getItem("DefaultLanguage")!;
  //isKering: boolean = switchCompanyRedirect();
  contactInfo: footerContactInfo[] = [];

  policies: PolicyItem[] = [];
  countryFlag: string = "";
  showCountrySelect: boolean = environment.SHOW_COUNTRY_SELECT;
  subs: Subscription = new Subscription();

  async ngOnInit() {
    await this.getContactInfos();
    this.countryFlag = environment.COUNTRY_SELECT.find(f=> f.COUNTRY_DEFAULT_LANGUAGE.strEq(this.defLan) &&  f.COUNTRY_BASE_PATH.strEq(this.basePath))?.COUNTRY_FLAG??"it"
    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('footer')))[0]?.policies ?? []
    }))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async getContactInfos() {
    await this.apiProxySvc.makeRequestErrorFunction<SaleEventContactInfoItem>(() => this.bookSvc.getFooterContactInfo(this.tsvc.currentLanguage.value), 
    true, "general", 100, undefined, (res: PurpleApiMakeRequestResponse<SaleEventContactInfoItem>) => {
        this.contactInfo = [
          {
            infoIcon: "environment",
            infoValue: res.data?.address?.addressFull??""
          },
          {
            infoIcon: "phone",
            infoValue: res.data?.phone??""
          },
          {
            infoIcon: "mail",
            infoValue: res.data?.email??""
          }
        ];
      
    })
  }

  changeCountry() {
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_change_country_title", "Cambia nazione?"),
        nzContent: this.tranPipe.transform("modal_change_country_subtitle", "Sei sicuro di voler cambiare nazione? assicurati di avere un account valido per la nazione che selezionerai", []),
        nzWidth: this.breakSvc.breakPointDevice$.value == 'mobile' ? '80%' : '500px',
        nzClassName: 'ps-modal full-button',
        nzCentered: this.breakSvc.breakPointDevice$.value == 'mobile' ? true : false,
        nzClosable: true,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_change_country_button", "Cambia"),
        nzCancelDisabled: true,
        nzCancelText: null,
        nzZIndex: 1000,
        nzOnOk: async () => {
          localStorage.clear();
          window.location.reload();
        }
      }
    )
  }
}

interface footerContactInfo{
  infoIcon: string;
  infoValue: string;
}