import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { LayoutService } from 'src/app/layout/layout.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { MembershipItem, MembershipItemVariant, MembershipManagerService } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-private-membership',
  templateUrl: './private-membership.component.html',
  styleUrls: ['./private-membership.component.scss']
})
export class PrivateMembershipComponent implements OnInit {

  constructor(private tranPipe: PurpleTranslationPipe, private memberSvc: MembershipManagerService, private tsvc: AppTranslationService,
    private apiProxySvc: PurpleApiProxyService, private psNotSvc: PurpleSalesNotificationService, private router: Router, public layoutSvc: LayoutService) { }

  pageTitle: pageTitle = {
    title: this.tranPipe.transform('membership_title', 'Scopri le nostre membership', []),
    titleTranslationKey: 'membership_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform('membership_subtitle', 'ABBONATI PER RICEVERE VANTAGGI ESCLUSIVI', []),
      subtitleTranslationKey: 'membership_subtitle'
    }
  }

  memberships: MembershipItem[] = [];

  async ngOnInit() {
    await this.getMemberships()
  }

  async getMemberships() {
    await this.apiProxySvc.makeRequestErrorFunction<MembershipItem[]>(() => this.memberSvc.getMemberships(this.tsvc.currentLanguage.value, {
      saleEventId: Guid.empty()
    }), true, "general", 500, (res: PurpleApiMakeRequestResponse<MembershipItem[]>) => {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('membership_error_title', 'Qualcosa è andato storto', []),
        this.tranPipe.transform('membership_error_subtitle', 'Impossibile recuperare le memberships', []),
        this.tranPipe.transform('membership_error_button', 'Riprova', []),
        async () => { await this.getMemberships() }, 'modal', true, undefined, undefined, false, false, false, false)
    }, async (res: PurpleApiMakeRequestResponse<MembershipItem[]>) => {
      this.memberships = res.data ?? []
    });
  }

  getAltPrices(prices: Array<string> | null | undefined): {key: string, value: string}[] {
    if (!prices) {
      return [];
    }

    return prices.slice(1).map(m=> {
      return {
        key: m,
        value: this.tranPipe.transform(m,m, [])
      }
    })
  }


  checkUserMembershipIsActive(variants: Array<MembershipItemVariant> | null | undefined): {isSub: boolean, isFree: boolean} {
    const subscription = ((variants ?? []).map(f => f.hasUserThisMembershipActive))
    .includes(true);

    const allSubs = this.memberships.filter(f=> !((f.membershipVariants ?? []).every(e => e.variantPrice == 0)))
    .flatMap(f=> (f.membershipVariants??[]))
    .map(m=>m.hasUserThisMembershipActive).includes(true);

    const isFree = (variants ?? []).every(e => e.variantPrice == 0);

    if (subscription || (!allSubs && isFree)) {
      return {isSub: true, isFree: (!allSubs && isFree)}
    }

    return {isSub: false, isFree: isFree}

  }


  getBtnText(membershipName: string | null | undefined, variants: Array<MembershipItemVariant> | null | undefined): { text: string, key: string } {
    const check = this.checkUserMembershipIsActive(variants);

    if (check.isSub) {
      return { text: this.tranPipe.transform('membership_button_active', 'Iscritto', []), key: "membership_button_active" }
    }

    if(check.isFree){
      return { text: this.tranPipe.transform('membership_button_free', 'Gratis', []), key: "membership_button_free" }
    }

    if((membershipName?.toLowerCase()??"").includes("elite")){
      return { text: this.tranPipe.transform('membership_button_text', 'Fai Richiesta', []), key: "membership_button_text" }
    }

    return { text: this.tranPipe.transform('membership_button_subscribe', 'Abbonati', []), key: "membership_button_subscribe" }

  }



  subscribeBtn(membId: string, variants: Array<MembershipItemVariant> | null | undefined) {
    const check = this.checkUserMembershipIsActive(variants);

    if(!check.isFree){
      this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership-detail'], {
        queryParams: { membership: membId }
      })
    }

  }
}
