import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-purple-sales-page-title',
  templateUrl: './purple-sales-page-title.component.html',
  styleUrls: ['./purple-sales-page-title.component.scss']
})
export class PurpleSalesPageTitleComponent implements OnInit {

  constructor() { }
  @Input() pageTitle!: pageTitle;

  ngOnInit() {
  }

  /* async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const currentValue = changes['pageTitle']?.currentValue ?? undefined;
    const previousValue = changes['pageTitle']?.previousValue ?? undefined;

    if (currentValue != null && currentValue != undefined &&
      previousValue != null && previousValue != undefined &&
      currentValue != previousValue) {
        console.log("CHANGE: ", changes['pageTitle'])
      this.pageTitle = changes['pageTitle'].currentValue;
    }
  } */

}

export interface pageTitle{
  titleTranslationKey: string;
  title: string;
  subtitle: {
    subtitle: string;
    subtitleTranslationKey: string;
  } | undefined;
  textAlign?: 'center' | 'right' | 'left' | undefined
}