<div class="advanced-booking-sale-detail-banner-container ps-d-flex ps-flex-wrap"
  contentId="{{saleEventDetail!.saleEvent.saleEventId}}-banner" 
  contentTypeId="sale-event-detail-banner-image"
  purpleEditableImage>
  <!-- <div
    class="advanced-booking-sale-detail-banner-dark-layer ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center ps-position-relative">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100">
      <h1 class="advanced-sale-event-name ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h1>
    </div>
  </div> -->
</div>
<div class="advanced-box-information-container-external">

  <!-- DESKTOP -->
  <div class="advanced-box-information-container ps-flex-wrap sw-border-radius">
    <!-- <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100 ps-mb-4">
      <h1 class="advanced-sale-event-name purple-sales-title ps-w-100 ps-text-center">
        {{saleEventDetail!.saleEvent.saleEventName}}
      </h1>
    </div> -->
    <app-purple-sales-page-title class="advanced-sale-event-name"
      [pageTitle]="saleEventTitle"></app-purple-sales-page-title>

    <div class="ps-w-100 ps-d-flex ps-flex-wrap advanced-box-information-container-internal">
      <div class="ps-d-flex ps-flex-wrap advanced-box-information-main-container">
        <h2 class="info-title" purpleEditableText tranKey='advanced_sale_event_banner_about_sale_event'>
          {{ 'advanced_sale_event_banner_about_sale_event' | translation: 'Sulla vendita': []}}
        </h2>
        <div class="info-text ps-p-0 ps-w-100" [innerHtml]="saleEventDetailText | safeHtml"></div>
        <ng-container class="ps-w-100 ps-d-flex ps-flex-wrap" 
        *ngIf="saleEventDetail!.saleEvent.saleEventDetailItemsQty || saleEventDetail!.saleEvent.saleEventDetailAvgDiscount
         || saleEventDetail!.saleEvent.saleEventDetailMaxDiscount || saleEventDetail!.attributes.length > 0">
          <h2 class="info-title" purpleEditableText tranKey='advanced_sale_event_banner_title'>
            {{ 'advanced_sale_event_banner_title' | translation: 'Cosa troverai?': []}}
          </h2>
          <div class="discount-container" *ngIf="saleEventDetail!.saleEvent.saleEventDetailItemsQty || saleEventDetail!.saleEvent.saleEventDetailAvgDiscount
          || saleEventDetail!.saleEvent.saleEventDetailMaxDiscount">
            <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailItemsQty">
              <div class="ps-dlfex ps-flex-wrap ps-w-100">
                <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailItemsQty}}</p>
                <p class="discount-text">{{ 'advanced_sale_event_banner_items_qty' | translation: 'articoli': []}}</p>
              </div>
            </div>
            <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailAvgDiscount">
              <div class="ps-dlfex ps-flex-wrap ps-w-100">
                <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailAvgDiscount}}</p>
                <p class="discount-text">{{ 'advanced_sale_event_banner_avg_discount' | translation: 'sconto medio':
                  []}}</p>
              </div>
            </div>
            <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailMaxDiscount">
              <div class="ps-dlfex ps-flex-wrap ps-w-100">
                <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailMaxDiscount}}</p>
                <p class="discount-text">{{ 'advanced_sale_event_banner_avg_discount' | translation: 'sconto più alto':
                  []}}</p>
              </div>
            </div>
          </div>
          <div class="sale-event-attributes" *ngIf="saleEventDetail!.attributes.length > 0">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap">
              <div class="ps-col-6" *ngFor="let defVal of saleEventDetail!.attributes">
                <button nz-button class="sale-attribute-button {{defVal.isSelected?'selected':''}}">
                  {{defVal.userAttributeDefaultValueText | uppercase}}
                </button>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
      <div class="ps-d-flex ps-flex-wrap advanced-box-information-side-container ps-align-items-start">

        <div class="ps-d-flex ps-flex-wrap">

          <h2 class="ps-w-100 info-title" purpleEditableText tranKey='advanced_sale_event_banner_when_sale_event'>
            {{ 'advanced_sale_event_banner_when_sale_event' | translation: 'Quando?': []}}
          </h2>
          <div purpleEditableText tranKey='advanced_sale_event_banner_when_2_sale_event'
            class="ps-p-0 ps-w-100 ps-mb-0 info-text" [innerHtml]="'advanced_sale_event_banner_when_2_sale_event' | translation: 'Dal {0} \</br> al {1}': [
          (saleEventDetail!.userSlotStartDate| saleEventDate:'dd MMMM')??'', (saleEventDetail!.userSlotEndDate| saleEventDate:'dd MMMM')??''
          ]"></div>
          <h2 class="ps-w-100 where info-title" purpleEditableText
            tranKey='advanced_sale_event_banner_where_sale_event'>
            {{ 'advanced_sale_event_banner_where_sale_event' | translation: 'Dove?': []}}
          </h2>
          <div class="ps-w-100 ps-d-flex ps-align-items-center">
            <!-- <i class="fsp-18-0 ps-mr-2" nz-icon nzType="home" nzTheme="outline"></i> -->
            <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
            '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank"
              class="sale-event-address info-text">
              @if(saleEventDetail.saleEvent.addressExtra){<span>{{saleEventDetail.saleEvent.addressExtra}} - </span>} {{ saleEventDetail!.saleEvent.addressFull}}
            </a>
          </div>
          <ng-container *ngIf="checkCanInvite()">
            <h2 class="ps-w-100 invite info-title" purpleEditableText
              tranKey='advanced_sale_event_banner_invite_sale_event'>
              {{ 'advanced_sale_event_banner_invite_sale_event' | translation: 'Invita Amici': []}}
            </h2>
            <div class="ps-w-100 ps-d-flex ps-justify-content-center">
              <app-purple-sales-button (click)="showInviteFriendsModal()" class="auth-main-button" purpleEditableText
                tranKey='invite_friend_detail_button'
                [buttonText]="'invite_friend_detail_button' | translation: 'Invita un amico'">
              </app-purple-sales-button>
            </div>
          </ng-container>

        </div>
      </div>
    </div>


  </div>

  <!-- MOBILE -->
  <div class="mobile-advanced-box-information-container ps-flex-wrap sw-border-radius">
    <div class="ps-d-flex ps-flex-wrap mobile-advanced-box-information-internal-container ps-align-items-start">
      <app-purple-sales-page-title class="advanced-sale-event-name" [pageTitle]="saleEventTitle">
      </app-purple-sales-page-title>

      <h2 class="ps-w-100 info-title" purpleEditableText tranKey='advanced_sale_event_banner_when_sale_event'>
        {{ 'advanced_sale_event_banner_when_sale_event' | translation: 'Quando?': []}}
      </h2>
      <div purpleEditableText tranKey='advanced_sale_event_banner_when_2_sale_event'
        class="ps-p-0 ps-w-100 ps-mb-0 info-text" [innerHtml]="'advanced_sale_event_banner_when_2_sale_event' | translation: 'Dal {0} \</br> al {1}': [
        (saleEventDetail!.userSlotStartDate| saleEventDate:'dd MMMM')??'', (saleEventDetail!.userSlotEndDate| saleEventDate:'dd MMMM')??''
        ]"></div>
      <h2 class="ps-w-100 where info-title" purpleEditableText tranKey='advanced_sale_event_banner_where_sale_event'>
        {{ 'advanced_sale_event_banner_where_sale_event' | translation: 'Dove?': []}}
      </h2>
      <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center">
        <!-- <i class="fsp-18-0 ps-mr-2" nz-icon nzType="home" nzTheme="outline"></i> -->
        <a [href]="'https://www.google.com/maps/search/?api=1&query='+saleEventDetail!.saleEvent.addressLat+'-'+saleEventDetail!.saleEvent.addressLon+
          '&query_place_id='+saleEventDetail!.saleEvent.addressExternalId" target="_blank"
          class="sale-event-address info-text">
          @if(saleEventDetail.saleEvent.addressExtra){<span>{{saleEventDetail.saleEvent.addressExtra}} - </span>} {{ saleEventDetail!.saleEvent.addressFull}}
        </a>
      </div>
      <h2 class="info-title" purpleEditableText tranKey='advanced_sale_event_banner_about_sale_event'>
        {{ 'advanced_sale_event_banner_about_sale_event' | translation: 'Sulla vendita': []}}
      </h2>
      <div class="info-text ps-p-0 ps-w-100 ps-text-center" [innerHtml]="saleEventDetailText | safeHtml"></div>
      <ng-container class="ps-w-100 ps-d-flex ps-flex-wrap" *ngIf="saleEventDetail!.saleEvent.saleEventDetailItemsQty || saleEventDetail!.saleEvent.saleEventDetailAvgDiscount
         || saleEventDetail!.saleEvent.saleEventDetailMaxDiscount || saleEventDetail!.attributes.length > 0">
        <h2 class="info-title" purpleEditableText tranKey='advanced_sale_event_banner_title'>
          {{ 'advanced_sale_event_banner_title' | translation: 'Cosa troverai?': []}}
        </h2>
        <div class="discount-container">
          <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailItemsQty">
            <div class="ps-dlfex ps-flex-wrap ps-w-100">
              <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailItemsQty}}</p>
              <p class="discount-text">{{ 'advanced_sale_event_banner_items_qty' | translation: 'articoli': []}}</p>
            </div>
          </div>
          <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailAvgDiscount">
            <div class="ps-dlfex ps-flex-wrap ps-w-100">
              <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailAvgDiscount}}</p>
              <p class="discount-text">{{ 'advanced_sale_event_banner_avg_discount' | translation: 'sconto medio':
                []}}</p>
            </div>
          </div>
          <div class="discount-container-item ps-col-4" *ngIf="saleEventDetail!.saleEvent.saleEventDetailMaxDiscount">
            <div class="ps-dlfex ps-flex-wrap ps-w-100">
              <p class="discount-value">{{saleEventDetail!.saleEvent.saleEventDetailMaxDiscount}}</p>
              <p class="discount-text">{{ 'advanced_sale_event_banner_avg_discount' | translation: 'sconto più alto':
                []}}</p>
            </div>
          </div>
        </div>
        <div class="sale-event-attributes">
          <div class="ps-w-100 ps-d-flex ps-flex-wrap">
            <div class="ps-col-12" *ngFor="let defVal of saleEventDetail!.attributes">
              <button nz-button class="sale-attribute-button {{defVal.isSelected?'selected':''}}">
                {{defVal.userAttributeDefaultValueText | uppercase}}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      
      <h2 class="ps-w-100 invite info-title" purpleEditableText tranKey='advanced_sale_event_banner_invite_sale_event'>
        {{ 'advanced_sale_event_banner_invite_sale_event' | translation: 'Invita Amici': []}}
      </h2>
      <app-purple-sales-button (click)="showInviteFriendsDrawer()" class="auth-main-button" purpleEditableText
        tranKey='invite_friend_detail_button'
        [buttonText]="'invite_friend_detail_button' | translation: 'Invita un amico'">
      </app-purple-sales-button>
    </div>
  </div>

</div>