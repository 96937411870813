import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, SaleEventContactInfo } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { Guid, Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { SaleEventService } from 'src/app/sale-event-service.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent implements OnInit {
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  isLoading: boolean = false;

  contactInfo: SaleEventContactInfo | undefined;
  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });

  pageTitle: pageTitle = {
    title: this.tranPipe.transform("contact_title", "Entra in contatto", []),
    titleTranslationKey: 'contact_title',
    subtitle:
    {
      subtitle: this.tranPipe.transform("contact_subtitle", "con noi."),
      subtitleTranslationKey: 'contact_subtitle'
    }
  }

  constructor(public authSvc: AuthenticationService, private fb: FormBuilder, private tsvc: AppTranslationService, private route: ActivatedRoute, private seSvc: SaleEventService,
    private bookSvc: BookingService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private psNotSvc: PurpleSalesNotificationService) {
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async params => {
      var sec: string | undefined = params['saleEventCode'] ?? undefined;
      await this.getContactInfos(sec);
    });

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'fullname',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('contact_page_form_fullname', 'Nome e Cognome', []),
            fieldPlaceholder: this.tranPipe.transform('contact_page_form_fullname_placeholder', 'Nome e cognome', []),
            fieldValue: this.authSvc.isLoggedIn? this.authSvc.currentUser?.user?.firstName + " " + this.authSvc.currentUser?.user?.lastName : undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldIsDisabled: this.authSvc.isLoggedIn
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('contact_page_form_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('contact_page_form_email_placeholder', 'Email', []),
            fieldValue: this.authSvc.isLoggedIn? this.authSvc.currentUser?.user?.email : undefined,
            fieldIsRequired: true,
            fieldIsDisabled: this.authSvc.isLoggedIn,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldCustomValidators: [Validators.email],
            fieldCustomValidatorErrors: [{ errorId: "email", text: this.tranPipe.transform("invalid_email_validator_error_text", "Email non valida") }]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'phone',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('contact_page_form_phone', 'Numero di telefono', []),
            fieldPlaceholder: this.tranPipe.transform('contact_page_form_phone_placeholder', 'Numero di telefono', []),
            fieldValue: this.authSvc.isLoggedIn? this.authSvc.currentUser?.user?.phone : undefined,
            fieldIsRequired: true,
            fieldIsDisabled: this.authSvc.isLoggedIn,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldAffix: {
              fieldControlName: "phonePrefix",
              fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
              fieldType: "select-local",
              fieldWidth: "90px",
              fieldIsRequired: true,
              fieldIsDisabled: this.authSvc.isLoggedIn,
              fieldValue: this.authSvc.isLoggedIn? this.authSvc.currentUser?.user?.phonePrefix : undefined,
              fieldOptions: [
                { id: 'type', value: 'default' },
                { id: 'showArrow', value: false },
                {
                  id: 'options', value: this.phonePrefixes
                }
              ]
            },
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'message',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('contact_page_form_message', 'Messaggio', []),
            fieldPlaceholder: this.tranPipe.transform('contact_page_form_message_placeholder', 'Messaggio', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'rowNumber', value: 4 }]
          }
        ]
      }
    ]
  }


  async getContactInfos(sec: string | undefined) {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorFunction<SaleEventContactInfo>(() => this.bookSvc.getContactInfo(this.tsvc.currentLanguage.value, {
      saleEventCode: sec
    }), true, "general", 100, (res: PurpleApiMakeRequestResponse<SaleEventContactInfo>) => {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform("get_contact_infos_ko", "Ops! qualcosa è andato storto"),
        res.message ?? this.tranPipe.transform("get_contact_infos_subtitle_ko", "Impossibile recuperare le informazioni di contatto"),
        this.tranPipe.transform("get_contact_infos_button_ko", "Riprova"),
        async () => {
          await this.getContactInfos(sec);
        },
        'modal',
        true,
        undefined,
        undefined,
        false,
        false,
        false,
        false
      )
    }, (res: PurpleApiMakeRequestResponse<SaleEventContactInfo>) => {
      this.contactInfo = res.data;
      this.seSvc.currentSaleEvent.next(this.contactInfo?.currentSaleEvent)
      this.seSvc.getSaleEventUtcByTimeZoneId(this.contactInfo?.currentSaleEvent.timeZoneName);
    })
    this.isLoading = false;
  }


  async sendContactForm(): Promise<void> {

    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorFunction<boolean>(() => this.bookSvc.sendContactFormMessage(this.tsvc.currentLanguage.value, {
        saleEvent: this.contactInfo?.currentSaleEvent.saleEventId ?? Guid.empty(),
        email: this.validateForm.controls["email"].value,
        fullName: this.validateForm.controls["fullname"].value,
        phone: undefined,
        phonePrefix: undefined,
        message: this.validateForm.controls["message"].value,
        clientHost: window.location.hostname
      }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform("send_contact_message_ko", "Ops! qualcosa è andato storto"),
          res.message ?? this.tranPipe.transform("send_contact_message_subtitle_ko", "Impossibile inviare il messaggio"),
          this.tranPipe.transform("send_contact_message_ko_button", "Riprova"),
          async () => {
            await this.sendContactForm();
          },
          'modal',
          true,
          undefined,
          undefined,
          true,
          true,
          true,
          true
        )
      }, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.psNotSvc.showMotSimpleNotification(
          this.tranPipe.transform("send_contact_message_ok", "Messaggio inviato"),
          this.tranPipe.transform("send_contact_message_subtitle_ok", "La tua richiesta è stata inviata correttamente, ti risponderemo al più presto!"),
          this.tranPipe.transform("send_contact_message_button_ok", "Ok"),
          undefined,
          'modal',
          true,
          undefined,
          undefined,
          false,
          false,
          false,
          false
        )
      })
    }
  }



}
