import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, registerResponse } from 'src/core-modules/authentication/authentication.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PolicyItem, PRPVSaleEventFull, FeedbackPolicy } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralService } from 'src/app/referral/referral.service';
import { Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { Guid, Helper, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../authentication.scss','./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  seId: string = "";
  fullAddressPlaceholder: string = "";
  companyName = environment.COMPANY_NAME ?? "purple_sales_backoffice";
  isLoading: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;

  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });
  policies: PolicyItem[] = [];
  
  registerBookingPolicies: FeedbackPolicy[] = [];
  subs: Subscription = new Subscription();

  pageTitle: pageTitle = {
    title: this.tranPipe.transform("register_title_new", "Benvenuto in {0}!", [this.companyName]),
    titleTranslationKey: 'register_title_new',
    subtitle:
    {
      subtitle: this.tranPipe.transform("register_subtitle", "Registrati se non hai un account saywow", [this.companyName]),
      subtitleTranslationKey: 'register_subtitle'
    },
    textAlign: 'left'
  }

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private bookSvc: BookingService, private router: Router, public policySvc: PolicyAndUserAttributesService,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, private refSvc: ReferralService) {
    this.fullAddressPlaceholder = environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', [])
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(params => {
      this.seSvc.getSaleEventParameters(params)
    });

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('register')))[0]?.policies ?? [];

      this.policies.forEach(policy => {
        this.validateForm.addControl(policy.policyId, new FormControl(false, policy.isMandatoryPolicy ? Validators.requiredTrue : null));
      });
    }));

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'firstname',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'lastname',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: [Validators.email],
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'phoneNumber',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldAffix: {
              fieldControlName: "phonePrefix",
              fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
              fieldType: "select-local",
              fieldWidth: "90px",
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldValue: undefined,
              fieldOptions: [
                { id: 'type', value: 'default' },
                { id: 'showArrow', value: false },
                {
                  id: 'options', value: this.phonePrefixes
                }
              ]
            },
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'password',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('register_page_form_password', 'Password', []),
            fieldPlaceholder: this.tranPipe.transform('register_page_form_password_placeholder', 'Password', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'isPassword', value: true }],
            fieldCustomValidators: [this.validatePassword],
            fieldCustomValidatorErrors: [{ errorId: "format", text: this.tranPipe.transform("invalid_email_validator_error_text", "La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)") }]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'checkPassword',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('register_page_form_check_password', 'Conferma password', []),
            fieldPlaceholder: this.tranPipe.transform('register_page_form_check_password_placeholder', 'Conferma password', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldCustomValidators: [this.confirmationValidator],
            fieldCustomValidatorErrors: [{ errorId: "match", text: this.tranPipe.transform("invalid_email_match_validator_error_text", "Le password inserite non corrispondono") }],
            fieldOptions: [{ id: 'isPassword', value: true }]
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'gender',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_gender', 'Sesso', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_gender_placeholder', 'Sesso utente', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldOptions: [
              { id: 'type', value: 'default' },
              { id: 'showArrow', value: true },
              {
                id: 'options', value: [
                  {
                    label: this.tranPipe.transform('edit_user_gender_man', 'Uomo', []),
                    value: "M"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_female', 'Donna', []),
                    value: "F"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_other', 'Altro', []),
                    value: "O"
                  }
                ]
              }
            ],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'address',
            fieldBootstrapColumn: 12,
            fieldName: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('edit_profile_form_address_title_full', 'Indirizzo', []) : this.tranPipe.transform('edit_profile_form_address_title_city', 'Città', []),
            fieldPlaceholder: environment.REGISTRATION_FULL_ADDRESS ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'searchFunction', value: this.addressSearchFunction },
              { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
            ]
          }
        ]
      }
    ]
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async register(): Promise<void> {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {

      this.policies.forEach(policy => {
        this.registerBookingPolicies.push({
          policyId: policy.policyId,
          policyCheck: this.validateForm.controls[policy.policyId].value
        })
      });

      await this.authenticationService.register(
        (this.validateForm.controls["firstname"].value as string).trim(),
        (this.validateForm.controls["lastname"].value as string).trim(),
        (this.validateForm.controls["email"].value as string).trim(),
        this.validateForm.controls["gender"].value,
        (this.validateForm.controls["password"].value as string).trim(),
        this.validateForm.controls["phoneNumber"].value??undefined,
        this.validateForm.controls["phonePrefix"].value??undefined,      
        this.validateForm.controls["address"].value,
        this.registerBookingPolicies,
        this.seSvc.returnUrl,
        this.seSvc.currentSaleEvent.value?.saleEventId ?? undefined,
        window.location.hostname
      ).then(
        async (registerRes: registerResponse) => {
          if (registerRes.registerOk) {
            this.authenticationService.login((this.validateForm.controls["email"].value as string).trim(), (this.validateForm.controls["password"].value as string).trim()).then(
              async (authenticated: boolean) => {
                if (authenticated) {
                  await this.refSvc.reedemReferralCode();

                  if (this.seSvc.returnUrl != undefined) {
                    await this.router.navigateByUrl(this.seSvc.returnUrl);
                  } else {
                    await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
                  }
                }
              }
            )
          }
        }
      )
    }
  }

  //#region Address
  addressSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }
  //#endregion



  validatePassword = (control: AbstractControl): ValidationErrors | null => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    if (!res) {
      return { format: true }
    }
    return {}
  }

  confirmationValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { match: true };
    }
    return {};
  };

  
}
